import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import api from './api'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userModalStoreModule from '@/views/admin/user/useStoreModule'
import adminBrandModal from '@/views/admin/admin-brand/useStoreModule'
import adminDepartmentModal from '@/views/admin/department/useStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    app,
    appConfig,
    verticalMenu,
    'admin-user-modal': userModalStoreModule,
    'admin-brand-modal': adminBrandModal,
    'admin-department-modal': adminDepartmentModal,
  },
  strict: process.env.DEV,
})
