import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)品牌列表
    getBrandList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/admin-brand', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一管理者
    getBrandData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/brands/${resolveData.brand_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)品牌
    setBrandCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/admin-brand', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)品牌
    setBrandUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/admin-brand/${resolveData.brand_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)品牌
    setBrandDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/admin-brand/${resolveData.brand_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
