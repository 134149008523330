import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    imageExplorerType: 'images',
  },
  getters: {},
  mutations: {
    UPDATE_IMAGE_EXPLORER_TYPE_STATE(state, val) {
      state.imageExplorerType = val
    },
  },
  actions: {
    // --------------------------------------簡易查詢------------------------------------------
    // (簡易查詢)分類列表
    getMetaList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/system/meta', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (生成)圖形驗證
    getImgCaptcha(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/captcha', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    checkToken(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/check', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // --------------------------------------------------------------------------------------
    // (查詢)圖片總管
    getImageExplorer(ctx, serverData) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/config/image', { headers: { 'Access-Token': useJwt.getToken() }, params: serverData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)圖片
    uploadImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios('/admin/config/image', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: imageData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)圖片
    deleteImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios('/admin/config/image', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...imageData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)圖片
    updateImageExplorer(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/config/image/${imageData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...imageData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --------------------------------------個人化設定------------------------------------------
    // (查詢)個人化設定
    getConfigData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/config', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)個人化設定
    setConfigUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/config', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // --------------------------------------------------------------------------------------
    // (登出)
    logout() {
      return new Promise((resolve, reject) => {
        axios('/auth/logout', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
