export default [
  {
    path: '/admin/page/random',
    name: 'admin-page-random',
    component: () => import('@/views/pages/random/Random.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '密碼產生器',
      breadcrumb: [
        {
          text: '快速功能',
          active: true,
        },
        {
          text: '密碼產生器',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/page/image',
    name: 'admin-page-image',
    component: () => import('@/views/pages/image/Image.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '圖片轉換',
      breadcrumb: [
        {
          text: '快速功能',
          active: true,
        },
        {
          text: '圖片轉換',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/page/base64/image',
    name: 'admin-page-base64-image',
    component: () => import('@/views/pages/base64/Image.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '圖片轉Base64',
      breadcrumb: [
        {
          text: '快速功能',
          active: true,
        },
        {
          text: '圖片轉Base64',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/page/base64/convert',
    name: 'admin-page-base64-convert',
    component: () => import('@/views/pages/base64/Convert.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Base64轉碼',
      breadcrumb: [
        {
          text: '快速功能',
          active: true,
        },
        {
          text: 'Base64轉碼',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/login.html',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/admin/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      // layout: 'full',
    },
  },
]
