import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)部門列表
    getDepartmentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/departments', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一管理者
    getDepartmentData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/departments/${resolveData.department_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)部門
    setDepartmentCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/departments', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)部門
    setDepartmentUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/departments/${resolveData.department_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)部門
    setDepartmentDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/departments/${resolveData.department_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
