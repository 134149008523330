// import store from '@/store'

export default [
  // -------------------------------後台首頁----------------------------------
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('@/views/admin/home/Home.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '後台首頁',
      breadcrumb: [
        {
          text: '後台首頁',
          active: true,
        },
      ],
    },
  },
  // *====================================================================*
  // *-------------------------------使用者-------------------------------*
  // *====================================================================*
  {
    path: '/admin/users',
    name: 'admin-user',
    component: () => import('@/views/admin/user/user-list/UsersList.vue'),
    meta: {
      isAdmin: true,
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: '員工列表',
      breadcrumb: [
        {
          text: '員工列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/user/view/:id',
    name: 'admin-user-view',
    component: () => import('@/views/admin/user/user-view/UserView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '員工詳情',
      breadcrumb: [
        {
          text: '員工列表',
          to: '/admin/users',
        },
        {
          text: '員工詳情',
          active: true,
        },
      ],
      navActiveLink: 'admin-user',
      belong: 'admin-user',
    },
  },
  // *====================================================================*
  // *-------------------------------部門-------------------------------*
  // *====================================================================*
  {
    path: '/admin/department',
    name: 'admin-department',
    component: () => import('@/views/admin/department/department-list/DepartmentList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '部門列表',
      breadcrumb: [
        {
          text: '部門列表',
          active: true,
        },
      ],
    },
  },
  // *====================================================================*
  // *-------------------------------地點-------------------------------*
  // *====================================================================*
  {
    path: '/admin/place',
    name: 'admin-place',
    component: () => import('@/views/admin/place/place-list/PlaceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '地點列表',
      breadcrumb: [
        {
          text: '地點列表',
          active: true,
        },
      ],
    },
  },
  // *====================================================================*
  // *-------------------------------品牌-------------------------------*
  // *====================================================================*
  {
    path: '/admin/brand',
    name: 'admin-brand',
    component: () => import('@/views/admin/brand/brand-list/BrandList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '品牌列表',
      breadcrumb: [
        {
          text: '品牌列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/brand/admin',
    name: 'admin-brand-admin',
    component: () => import('@/views/admin/admin-brand/brand-list/BrandList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '公司品牌',
      breadcrumb: [
        {
          text: '公司品牌',
          active: true,
        },
      ],
    },
  },
  // *====================================================================*
  // *-------------------------------設備-------------------------------*
  // *====================================================================*
  {
    path: '/admin/device',
    name: 'admin-device',
    component: () => import('@/views/admin/device/device-list/DeviceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '設備列表',
      breadcrumb: [
        {
          text: '設備列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/device/pc',
    name: 'admin-device-pc',
    component: () => import('@/views/admin/device/device-list-pc/DeviceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '主機管理',
      breadcrumb: [
        {
          text: '主機管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/device/pc/:id',
    name: 'admin-device-pc-view',
    component: () => import('@/views/admin/device/device-view-pc/DeviceViewPC.vue'),
    meta: {
      isAdmin: true,
      belong: 'admin-device-pc',
      pageTitle: '主機詳情',
      breadcrumb: [
        {
          text: '主機管理',
          to: '/admin/device/pc',
        },
        {
          text: '主機詳情',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/device/cpu',
    name: 'admin-device-cpu',
    component: () => import('@/views/admin/device/device-list-cpu/DeviceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'CPU管理',
      breadcrumb: [
        {
          text: '庫存管理',
          active: true,
        },
        {
          text: 'CPU管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/device/memory',
    name: 'admin-device-memory',
    component: () => import('@/views/admin/device/device-list-memory/DeviceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '記憶體管理',
      breadcrumb: [
        {
          text: '庫存管理',
          active: true,
        },
        {
          text: '記憶體管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/device/hard-disk',
    name: 'admin-device-disk',
    component: () => import('@/views/admin/device/device-list-disk/DeviceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '硬碟管理',
      breadcrumb: [
        {
          text: '庫存管理',
          active: true,
        },
        {
          text: '硬碟管理',
          active: true,
        },
      ],
    },
  },
  // *====================================================================*
  // *-------------------------------客戶列表-------------------------------*
  // *====================================================================*
  {
    path: '/admin/customer',
    name: 'admin-customer',
    component: () => import('@/views/admin/customer/customer-list/Customer.vue'),
    meta: {
      // layout: 'full',
      isAdmin: true,
      contentRenderer: 'sidebar-customer',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/admin/customer/:id',
    name: 'admin-customer-view',
    component: () => import('@/views/admin/customer/customer-list/Customer.vue'),
    meta: {
      isAdmin: true,
      contentRenderer: 'sidebar-customer',
      contentClass: 'chat-application',
      navActiveLink: 'admin-customer',
      belong: 'admin-customer',
    },
  },
  // *====================================================================*
  // *-------------------------------待辦事項-------------------------------*
  // *====================================================================*
  {
    path: '/admin/todo/group',
    name: 'admin-todo-group',
    component: () => import('@/views/admin/todo/todo-group/todo-list/GroupList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '群組權限',
      breadcrumb: [
        {
          text: '待辦事項',
          active: true,
        },
        {
          text: '群組權限',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/todo/group/:id',
    name: 'admin-todo-group-view',
    component: () => import('@/views/admin/todo/todo-group/todo-view/GroupView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '群組權限',
      breadcrumb: [
        {
          text: '待辦事項',
          active: true,
        },
        {
          text: '群組權限',
          to: { name: 'admin-todo-group' },
        },
        {
          text: '權限設定',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/todo/class',
    name: 'admin-todo-class',
    component: () => import('@/views/admin/todo/todo-class/ClassList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '作業分類',
      breadcrumb: [
        {
          text: '待辦事項',
          active: true,
        },
        {
          text: '作業分類',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/todo/execution',
    name: 'admin-todo-execution',
    component: () => import('@/views/admin/todo/todo-execution/ClassList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '項目設定',
      breadcrumb: [
        {
          text: '待辦事項',
          active: true,
        },
        {
          text: '項目設定',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/todo',
    name: 'admin-todo',
    component: () => import('@/views/admin/todo/todo-list/Todo.vue'),
    meta: {
      // layout: 'full',
      isAdmin: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/admin/todo/:id',
    name: 'admin-todo-view',
    component: () => import('@/views/admin/todo/todo-list/Todo.vue'),
    meta: {
      isAdmin: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      navActiveLink: 'admin-todo',
      belong: 'admin-todo',
    },
  },
  // *====================================================================*
  // *-------------------------------AI機器人列表-------------------------------*
  // *====================================================================*
  {
    path: '/admin/openAI',
    name: 'admin-openAI',
    component: () => import('@/views/admin/openAI/openAI-list/OpenAIList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'AI機器人列表',
      breadcrumb: [
        {
          text: 'AI機器人列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/openAI/room',
    name: 'admin-openAI-room',
    component: () => import('@/views/admin/openAI/openAI-view/OpenAI.vue'),
    meta: {
      // layout: 'full',
      isAdmin: true,
      // isAdmin: true,
      contentRenderer: 'sidebar-openAi',
      contentClass: 'chat-application',
      // belong: 'admin-openAI',
    },
  },
  {
    path: '/admin/openAI/room/:id',
    name: 'admin-openAI-room-view',
    component: () => import('@/views/admin/openAI/openAI-view/OpenAI.vue'),
    meta: {
      isAdmin: true,
      contentRenderer: 'sidebar-openAi',
      contentClass: 'chat-application',
      // navActiveLink: 'admin-customer',
      // belong: 'admin-openAI',
    },
  },
]
